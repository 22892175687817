<template>
  <div id="addModify">
    <div class="closeBut"><i style="cursor: pointer" @click="$router.back()" class="el-icon-close"></i></div>
    <a-form-model ref="dataForm" :model="form" :rules="rules" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
      <a-form-model-item label="‘协议类型" prop="type">
        <a-radio-group :disabled="true" v-model="form.type">
          <a-radio :value="1">通勤巴士用户协议</a-radio>
          <a-radio :value="2">积分支付用户协议</a-radio>
          <a-radio :value="3">App用户协议</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="协议名称" prop="agreementName">
        <a-input
          placeholder="请输入名称"
          :disabled="true"
          style="width: 40%"
          :maxlength="20"
          v-model="form.agreementName"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="适用项目" prop="projectIds">
        <a-checkbox :checked="isAllChecked" :disabled="isDisabled || this.type === 'view'" @change="checkBoxOnHandleChange"/>全选
        <a-select  style="width: 40%" :disabled="isDisabled || this.type === 'view'" v-model="form.projectIds" mode="multiple">
          <a-select-option v-for="item in projectList" :key="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <div class="ma-15" :style="{ width: divWidth, height: windowHeight }">
          <div v-html="form.agreementContent" class="editor"></div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="butClass" v-show="!type">
      <a-button type="info" @click="$router.back()" class="back">取消 </a-button>
      <a-button style="margin-left: 15px" type="primary" @click="save" class="back">发布 </a-button>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/userMessage";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { projectName } from '@/api/modularManagement/index.js'
import { NOT_PROD } from '@/config'
const titleConfig = {
  "ql-bold": "加粗",
  "ql-color": "颜色",
  "ql-font": "字体",
  "ql-code": "插入代码",
  "ql-italic": "斜体",
  "ql-link": "添加链接",
  "ql-background": "颜色",
  "ql-size": "字体大小",
  "ql-strike": "删除线",
  "ql-script": "上标/下标",
  "ql-underline": "下划线",
  "ql-blockquote": "引用",
  "ql-header": "标题",
  "ql-indent": "缩进",
  "ql-list": "列表",
  "ql-align": "文本对齐",
  "ql-direction": "文本方向",
  "ql-code-block": "代码块",
  "ql-formula": "公式",
  "ql-image": "图片",
  "ql-video": "视频",
  "ql-clean": "清除字体样式",
  "ql-upload": "文件",
  "ql-table": "插入表格",
  "ql-table-insert-row": "插入行",
  "ql-table-insert-column": "插入列",
  "ql-table-delete-row": "删除行",
  "ql-table-delete-column": "删除列",
};
export default {
  data() {
    return {
      id: "",
      type: false,
      isDisabled: false,
      windowHeight: document.documentElement.clientHeight - 400 + "px",
      divWidth: "80%",
      projectList: [],
      form: {
        type: 1,
        agreementName: "",
        agreementContent: "",
        projectIds: []
      },
      rules: {
        agreementName: [{ required: true, trigger: "blur", message: "请输入名称" }],
        type: [{required: true, trigger: "change", message: "请选择协议类型"}],
        projectIds: [{required: true, trigger: "change", message: "请选择适用项目"}]
      },
      quill: null,
      val: "<p>编辑部垃圾分类附近的房间<strong><em>牛牛牛牛牛女女的</em></strong>缴费拉菲<strong>经营性行行行行行行行行行行行行行行行行</strong></p>",
      options: {
        theme: "snow",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ script: "sub" }, { script: "super" }],
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
              [{ table: "TD" }],
              ["image", "video"],
            ],
            handlers: {
              table: function (val) {
                this.quill.getModule("table").insertTable(2, 3);
              },
              "table-insert-row": function () {
                this.quill.getModule("table").insertRowBelow();
              },
              "table-insert-column": function () {
                this.quill.getModule("table").insertColumnRight();
              },
              "table-delete-row": function () {
                this.quill.getModule("table").deleteRow();
              },
              "table-delete-column": function () {
                this.quill.getModule("table").deleteColumn();
              },
            },
          },
          table: true,
        },
        placeholder: "",
      },
    };
  },
  computed: {
    isAllChecked() {
      return this.projectList.length === this.form.projectIds.length
    }
  },
  activated() {
    this.quill.setContents({});
  },
  activated() {
    this.quill.setContents({});
  },
  async mounted() {
    this.type = this.$route.query.type;
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      await this.findAllProjects()
      await this.getDetail();
    }
    const dom = this.$el.querySelector(".editor");
    this.quill = new Quill(dom, this.options);
    this.quill.on("text-change", () => {
      this.$emit("contentData", this.quill.root.innerHTML);
    });
    this.addQuillTitle();
    this.$nextTick(() => {
      if (this.$route.query.type === 'view') this.quill.enable(false)
    })
  },
  methods: {
    checkBoxOnHandleChange(e) {
      if (e.target.checked) {
        this.form.projectIds = this.projectList.map(arr => {
          return arr.id
        })
      } else {
        this.form.projectIds = []
      }
    },
    // 获取所有项目
    async findAllProjects () {
      const res = await projectName();
      const { code, data: { items }} = res
      if (code === '200') {
        this.projectList = items
      }
    },
    addQuillTitle() {
      const oToolBar = document.querySelector(".ql-toolbar");
      const aButton = oToolBar.querySelectorAll("button");
      const aSelect = oToolBar.querySelectorAll("select");
      aButton.forEach(function (item) {
        if (item.className === "ql-script") {
          item.value === "sub" ? (item.title = "下标") : (item.title = "上标");
        } else if (item.className === "ql-indent") {
          item.value === "+1" ? (item.title = "向右缩进") : (item.title = "向左缩进");
        } else {
          item.title = titleConfig[item.classList[0]];
        }
      });
      aSelect.forEach(function (item) {
        item.parentNode.title = titleConfig[item.classList[0]];
      });
    },
    getContentData() {
      return this.quill.getContents();
    },
    save() {
      this.$refs.dataForm.validate(async (valid) => {
        if (valid) {
          if (this.id) {
            let val = JSON.parse(JSON.stringify(this.form));
            val.agreementContent = this.quill.root.innerHTML;
            val.projectIds = val.projectIds.toString()
            const res = await api.agreementUpdate(val);
            if (res.code === "200") {
              this.$message.success("修改成功!");
              this.$router.go(-1);
            }
          } else {
            this.form.agreementContent = this.quill.root.innerHTML;
            const res = await api.agreementInsert(this.form);
            if (res.code === "200") {
              this.$message.success("添加成功!");
              this.$router.go(-1);
            }
          }
        } else {
        }
      });
    },
    async getDetail() {
      const res = await api.findById({ id: this.id });
      console.log(res)
      const { data } = res
      this.isCheck(data.agreementName, data.projectIds)
      data.projectIds = data.projectIds.split(',')
      this.form = data;
    },
    isCheck(name, projectIds) {
      // 大兴国际氢能示范区的通勤巴士用户协议
      if (name === '通勤巴士用户协议' && projectIds === (NOT_PROD ? '02b9c47a12f00000' : '03c1c8dde7710000')) {
        this.isDisabled = true
        if (NOT_PROD) {
          this.projectList = this.projectList.filter(arr => arr.id === '02b9c47a12f00000')
        } else {
          this.projectList = this.projectList.filter(arr => arr.id === '02b9c47a12f00000')
        }
        return 1
      }
      // 非大兴国际氢能示范区的通勤巴士用户协议
      if (name === '通勤巴士用户协议') {
        if (NOT_PROD) {
          this.projectList = this.projectList.filter(arr => arr.id !== '02b9c47a12f00000')
        } else {
          this.projectList = this.projectList.filter(arr => arr.id !== '02b9c47a12f00000')
        }
        return 2
      }
      // 其他协议
      return 3
    }
  },
};
</script>

<style scoped>
::v-deep strong {
  font-weight: 600;
}
.butClass {
  margin-top: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .ql-snow .ql-picker.ql-size {
  width: 80px;
}
::v-deep .ql-size .ql-picker-label {
  display: flex;
}
.closeBut {
  text-align: right;
  font-size: 25px;
  margin-bottom: 20px;
}
.ma-15 {
  margin-top: 15px;
}
</style>
